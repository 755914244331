import axios from "axios";
import Vue from "vue";
import {TOAST_DEFAULT} from "@/constants/toast/toastConstants";
import i18n from "@/i18n/i18n";
import Router, {ROUTE_ACTIVATE, ROUTE_LOGIN} from '../router';
import {store} from "@/store/store";
import {LOGOUT_ACTION} from "@/store/modules/userModule/actions";

export const API = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 40000,
});

/**
 * Intercept errors
 */
API.interceptors.response.use(
  response => response,
  async error => {
    if (error.response && error.response.status === 500) {
      Vue.$toast.error(i18n.t('common.errors.globalError').toString(), TOAST_DEFAULT);
    }

    //if 401 and current route is not login, redirect to login
    if (error.response && error.response.status === 401 && window.location.pathname !== ROUTE_LOGIN.path && window.location.pathname !== ROUTE_ACTIVATE.path) {
      Vue.$toast.error(i18n.t('common.errors.unauthorized').toString(), TOAST_DEFAULT);
      await store.dispatch(LOGOUT_ACTION);
      await Router.push(ROUTE_LOGIN.path);
    }

    return Promise.reject(error);
  }
);

API.interceptors.request.use(
  config => {
    const token = store.getters.getUser?.token;
    if (token && !config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  });
