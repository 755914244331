export const TOAST_DEFAULT = {
  position: 'top-right',
  duration: 5000,
};


export const TOAST_ERROR = {
  position: 'top-right',
  duration: 5000,
  type: 'error'
};

export const TOAST_SUCCESS = {
  position: 'top-right',
  duration: 5000,
  type: 'success'
};
